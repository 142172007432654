import * as React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../styles/colors';
import Button from '../components/Button';
import {
  Body,
  Caption1,
  LargeTitle,
  Title1,
  Title2,
} from '../styles/typography';
import { StaticImage } from 'gatsby-plugin-image';
import gradient from '../images/gradient.png';
import { FaRegCopyright } from 'react-icons/fa';
import { FiArrowRight, FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'gatsby';
import { routes } from '../navigator/routes';
import { Helmet } from 'react-helmet';
import Section1Video from '../videos/section1.mp4';
import Section2Video from '../videos/section2.mp4';
import Section3Video from '../videos/section3.mp4';
import Section4Video from '../videos/section4.mp4';
import Testimonial from '../components/Testimonials';
import { SEO } from '../components/seo';
import isBrowser from '../util/gatsby';
import { GetUserInfo } from '../app-api/userService/UserApi';
import { Logout, ProfileInfo } from '../app-api/api';

const MainBannerWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  padding: 64px 24px 32px 24px;
  justify-items: center;
  /* position: sticky; */
  /* top: 81px; */
  z-index: 10;
  /* -webkit-transform: translate3d(0, 0, 0); */
`;

const MainBannerTextContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 12px;
  max-width: 644px;

  ${LargeTitle} {
    text-align: center;
    color: rgb(${(props) => props.theme.colors.neutral600});
  }

  ${Body} {
    text-align: center;
    color: rgb(${(props) => props.theme.colors.neutral400});
  }
`;

const BetaTag = styled(Caption1)`
  padding: 4px 16px;
  color: rgb(${(props) => props.theme.colors.blue400});
  background: rgb(${(props) => props.theme.colors.blue100});
  justify-self: center;
  border-radius: 4px;
`;

const BodyContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0;
  padding: 0;
  margin-top: 32px;
  background: rgb(${(props) => props.theme.colors.white});
  position: relative;
  /* z-index: 10; */
  /* -webkit-transform: translate3d(0, 0, 0); */
`;

const ImageContainer = styled.div`
  padding: 0 64px 32px 64px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 53.12%,
      #ffffff 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
    url(${gradient});
  background-size: cover;
  background-position: center center;

  @media screen and (max-width: 1000px) {
    padding: 0 24px 32px 24px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 24px 32px 24px;
  }
`;

const ImageWrapper = styled.div`
  /* border: 1px solid rgb(${(props) => props.theme.colors.neutral150}); */
  border-radius: 4px;
  overflow: hidden;
  /* background: rgb(${(props) => props.theme.colors.white}); */
  margin: 0 auto;
  max-width: 1272px;
  padding: 0px 64px;
`;

const AppSectionContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  box-sizing: border-box;
  gap: 128px;
  justify-items: stretch;
  justify-self: center;
  width: 100%;
  max-width: 1000px;
  padding: 64px 0px;

  ${Title2} {
    color: rgb(${(props) => props.theme.colors.neutral600});
  }

  ${Body} {
    color: rgb(${(props) => props.theme.colors.neutral400});

    ul {
      list-style: disc;

      li {
        margin-left: 1.5em;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    gap: 64px;
    max-width: 468px;
    padding: 0 24px;
  }
  a {
    justify-self: center;
    margin-top: 16px;
  }
`;

const FooterWrapper = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 16px;
  }
`;

const Footer = styled.div`
  padding: 24px 0 32px 0;
  border-top: 1px solid rgb(${(props) => props.theme.colors.neutral150});
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-width: 1272px;
  justify-self: center;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }

  ${Caption1} {
    display: inline-grid;
    gap: 4px;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    color: rgb(${(props) => props.theme.colors.neutral400});
    text-decoration: none;

    :first-of-type {
      @media screen and (max-width: 800px) {
        margin-bottom: 32px;
      }
    }
  }
`;

const NavbarWrapper = styled.div`
  height: 80px;
  border-bottom: 1px solid rgb(${(props) => props.theme.colors.neutral600});
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  background: rgb(${(props) => props.theme.colors.white});
`;

const NavbarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: 1400px; */
  padding: 0 64px;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 16px;
  }
`;

const NavbarItems = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 800px) {
    grid-auto-flow: row;
    background: rgb(${(props) => props.theme.colors.white});
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    padding: 24px 16px;
    border-bottom: 1px solid rgb(${(props) => props.theme.colors.neutral600});
    text-align: center;
    justify-items: stretch;

    display: ${(props) => (props.expanded ? 'grid' : 'none')};

    ${Body} {
      border: 1px solid rgb(${(props) => props.theme.colors.neutral100});
      border-radius: 100px;
      text-align: center;
      height: 44px;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
    }

    ${Button} {
      justify-self: center !important;
    }
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;

const Hamburger = styled.div`
  width: 32px;
  height: 32px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgb(${(props) => props.theme.colors.offWhite});
  font-size: 24px;
  border: 1px solid rgb(${(props) => props.theme.colors.neutral100});
  border-radius: 4px;

  @media screen and (max-width: 800px) {
    display: flex;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  gap: 64px;
  align-items: center;
  /* padding: 0px 64px; */
  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    padding: 0px;
    gap: 24px;
  }
`;

const VideoWrapper = styled.video`
  border: 1px solid #e2e4e9;
  border-radius: 12px;
  box-sizing: border-box;
  outline: 1px solid #fff;
  padding-bottom: 0.1px;
  @media screen and (min-width: 1000px) {
    max-width: 468px;
  }
  @media screen and (max-width: 1000px) {
    object-fit: cover;
    max-width: 100%;
  }
`;

const VideoDescriptionContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;
const LandingPageItem = ({
  url,
  title,
  topDescription,
  bottomDescription,
  idx,
}) => (
  <ItemContainer reverse={idx % 2 !== 0}>
    <VideoWrapper loop muted autoPlay src={url} />
    <VideoDescriptionContainer>
      <Title1>{title}</Title1>
      <Body>{topDescription}</Body>
      {bottomDescription && <Body>{bottomDescription}</Body>}
    </VideoDescriptionContainer>
  </ItemContainer>
);

const Navbar = ({ loggedIn }) => {
  const [expanded, setExpanded] = React.useState(false);
  let fullName;
  if (isBrowser) {
    fullName = localStorage.getItem('fullName')?.split(' ').slice(0, -1);
  }

  return (
    <NavbarWrapper>
      <NavbarInner>
        <Title2>My Stories Matter</Title2>
        <Hamburger onClick={() => setExpanded(!expanded)}>
          {expanded ? <FiX /> : <FiMenu />}
        </Hamburger>
        <NavbarItems expanded={expanded}>
          {loggedIn ? (
            <Button to={routes.app.path}>Hello, {fullName}</Button>
          ) : (
            <>
              <Button to={routes.login.path}>Login</Button>
              <Button type={'primary'} to={'/auth/signup'}>
                Sign up
              </Button>
            </>
          )}
        </NavbarItems>
      </NavbarInner>
    </NavbarWrapper>
  );
};

const LandingPage = () => {
  const [loggedIn, setLoggedIn] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem('idToken')) {
      const checkToken = async () => {
        const userDetails = await ProfileInfo();
        console.log('userDetails', userDetails);

        if (userDetails.ResponseCode !== 200) {
          localStorage.clear();
        } else {
          setLoggedIn(true);
        }
      };

      checkToken();
    }
  }, []);

  const SectionItems = [
    {
      url: Section1Video,
      title: 'Made with you in mind',
      topDescription: `We like to say "a memory is what you make it.
         My Stories Matter is personalized and customizable to you and your needs. `,
      bottomDescription: `Whether it’s a daily journal entry, a life story, 
      a poem - a "memory" is yours to craft how you want.`,
    },
    {
      url: Section2Video,
      title: 'Journaling made easy',
      topDescription: `A core part of journaling is the writing process.  With our “less is more” approach, 
      focus on your journaling with an editor that’s designed to be distraction-free.`,
      bottomDescription: `Just input a title, the date you’d like your 
      writing to be recorded as, and journal away! `,
    },
    {
      url: Section3Video,
      title: 'Build the online journal that you’ve always wanted to',
      topDescription: 'See your written work grow and your journaling flow!',
      bottomDescription:
        'Find your perfect journaling rhythm and see your finished memories in order of when you publish them.',
    },
    {
      url: Section4Video,
      title: 'Continue to craft your memories',
      topDescription: `Come back and reflect on your journaling, or refine in-progress work. 
        You can revisit your journal entries with a new perspective every time.`,
    },
  ];
  const TestimonialItems = [
    {
      author: 'Happy Journaler',
      comment:
        'I can hardly wait to add more memories. It’s a fun app to have produced, thank you Arnie',
    },
    {
      author: 'Passionate Memoirist',
      comment: 'I would like to think that my stories matter too.',
    },
    {
      author: 'My Stories Matter Supporter',
      comment: 'Can’t wait to start my journal!!',
    },
    {
      author: 'Journaling Enthusiast',
      comment:
        'I can keep what I write private, which is exactly what I wanted.',
    },
    {
      author: 'Aspiring Biographer',
      comment: `I love to journal because I find it therapeutic but I’d like to go deeper into my journaling…one day 
      I’d love to write a book about my life but keep putting it off.`,
    },
    {
      author: 'Journal Queen',
      comment: 'I love journaling on it.',
    },
    {
      author: 'Fellow Creative Writer',
      comment:
        'It’s a beautiful platform and I’m very excited to record all of my thoughts and memories on it.',
    },
    {
      author: 'Journaling Peer',
      comment:
        'Thank you for creating this site. Everything about it is based on powerful principles.',
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Navbar loggedIn={loggedIn} />
      <MainBannerWrapper>
        <MainBannerTextContainer>
          <LargeTitle>
            Reflect, record, and process in a <br />
            journaling app made just for you
          </LargeTitle>
          <Body>
            Organize your thoughts and connect with yourself in My Stories
            Matter, a free digital platform designed to reminisce about life’s
            precious moments.
          </Body>
        </MainBannerTextContainer>
        <ButtonContainer>
          {loggedIn ? (
            <Button type={'primary'} to={routes.app.path}>
              Start journaling <FiArrowRight />
            </Button>
          ) : (
            <>
              <Button to={routes.login.path}>Login</Button>
              <Button type={'primary'} to={'/auth/signup'}>
                Sign up
              </Button>
            </>
          )}
        </ButtonContainer>
      </MainBannerWrapper>
      <BodyContainer>
        <ImageContainer>
          <ImageWrapper>
            <StaticImage
              src={'../images/mockup.png'}
              quality={90}
              loading="eager"
            />
          </ImageWrapper>
        </ImageContainer>
        <AppSectionContainer>
          {SectionItems.map((item, idx) => (
            <LandingPageItem
              key={`item - ${idx}`}
              url={item.url}
              title={item.title}
              topDescription={item.topDescription}
              bottomDescription={item.bottomDescription}
              idx={idx}
            />
          ))}
        </AppSectionContainer>
        <Testimonial items={TestimonialItems} />
        <FooterWrapper>
          <Footer>
            <Caption1>
              <FaRegCopyright /> {new Date().getFullYear()} My Stories Matter
            </Caption1>
            <Caption1
              as="a"
              href="https://www.mystoriesmatter.com"
              target="_blank"
            >
              Go to original My Stories Matter <FiArrowRight />
            </Caption1>
          </Footer>
        </FooterWrapper>
      </BodyContainer>
    </ThemeProvider>
  );
};

export default LandingPage;
