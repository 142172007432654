import React, { useEffect, useState } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import styled from 'styled-components';
import { Body, Callout, Title1 } from '../styles/typography';

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 52.08%,
      #ffffff 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
  box-sizing: border-box;
  overflow: hidden;
`;
const HeaderContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
  @media screen and (max-width: 600px) {
    padding: 24px;
  }
`;

const TestimonialHeader = () => {
  return (
    <HeaderContainer>
      <Title1 textAlign="center">What our community is saying</Title1>
      <Body textAlign="center">Names are anonymized for privacy</Body>
    </HeaderContainer>
  );
};

const ItemsContainer = styled.div`
  z-index: 10;
  padding: 64px 16px 192px 16px;
  display: flex;
  overflow: auto hidden;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  position: relative;
  scrollbar-width: none;
  @media screen and (max-width: 1000px) {
    padding-bottom: 112px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const GradientContainer = styled.div`
  position: absolute;
  inset: 0;
  width: 100vw;
  overflow: hidden;
`;

const Gradient = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.4;
  background: radial-gradient(#fce8f4 25%, transparent 50%),
    radial-gradient(#fce8f1 25%, transparent 50%),
    radial-gradient(#ede7fe 25%, transparent 50%),
    radial-gradient(#eceff9 25%, transparent 50%);
  background-size: 60% 80%, 70% 80%, 80% 80%, 80% 80%;
  background-position: 0 0, 0 100%, 50% 0, 50% 100%;
  background-repeat: no-repeat;
  z-index: -1;
  transform: scaleX(2);
`;

const AuthorName = styled(Callout)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 105%;
  display: flex;
  align-items: center;
`;
const TestimonialItem = styled.div`
  max-width: 250px;
  max-height: 262px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  gap: 12px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 4px rgba(11, 12, 15, 0.07),
    0px 4px 8px rgba(11, 12, 15, 0.07), 0px 8px 16px rgba(11, 12, 15, 0.07);
  border-radius: 12px;
`;

const TestimonialText = styled(Callout)`
  width: 202px;
  height: 152px;
`;
const TestimonialItems = ({ items, setItems }) => {
  useEffect(() => {
    let scrollWidth = document.getElementById('ksdpxI').scrollWidth;
    let prevScrollLeft = document.getElementById('ksdpxI').scrollLeft - 1;
    let intervalTImer = setInterval(() => {
      const container = document.getElementById('ksdpxI');
      if (prevScrollLeft === container.scrollLeft) {
        prevScrollLeft = -1;
        setItems((items) => [...items, ...items]);
      }

      if (container.scrollLeft !== container.scrollWidth) {
        prevScrollLeft = container.scrollLeft;
        container.scrollTo(container.scrollLeft + 1, 0);
      }
    }, 70);
    const onMouseEnter = (e) => {
      clearInterval(intervalTImer);
    };
    const onmouseleave = (e) => {
      intervalTImer = setInterval(() => {
        const container = document.getElementById('ksdpxI');
        if (prevScrollLeft === container.scrollLeft) {
          prevScrollLeft = -1;
          setItems((items) => [...items, ...items]);
        }

        if (container.scrollLeft !== scrollWidth) {
          prevScrollLeft = container.scrollLeft;
          container.scrollTo(container.scrollLeft + 1, 0);
        }
        scrollWidth = container.scrollWidth;
      }, 70);
    };
    const wrapper = document.getElementById('ksdpxI');
    wrapper.addEventListener('mouseenter', onMouseEnter);
    wrapper.addEventListener('mouseleave', onmouseleave);

    return () => {
      wrapper.removeEventListener('mouseenter', onMouseEnter);
      wrapper.removeEventListener('mouseleave', onmouseleave);
      clearInterval(intervalTImer);
    };
  }, [items, setItems]);
  return (
    <>
      <ItemsContainer id="ksdpxI">
        {items.map((item, idx) => (
          <TestimonialItem key={`testimonial - ${idx}`}>
            <FiMessageCircle />
            <TestimonialText>{item.comment}</TestimonialText>
            <AuthorName>{item.author}</AuthorName>
          </TestimonialItem>
        ))}
      </ItemsContainer>
    </>
  );
};
const Testimonial = ({ items }) => {
  const [testimonials, setTestimonials] = useState(items);
  useEffect(() => {}, [testimonials]);
  return (
    <Wrapper id="testimonial-wrapper">
      <TestimonialHeader />
      <TestimonialItems items={testimonials} setItems={setTestimonials} />
      <GradientContainer>
        <Gradient />
      </GradientContainer>
    </Wrapper>
  );
};

export default Testimonial;
